import { Component, JSX, children } from "solid-js";
import styles from "./Button.module.css";
import { A } from "@solidjs/router";

const ButtonLink: Component<{ href: string; children: JSX.Element }> = (
  props,
) => {
  return (
    <A class={styles.button} href={props.href}>
      {props.children}
    </A>
  );
};

export default ButtonLink;
