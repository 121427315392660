import { useLocation, useNavigate } from "@solidjs/router";
import { IoChevronBackOutline } from "solid-icons/io";
import { Component, createSignal, JSX, onMount, Show } from "solid-js";
import Button from "~/components/Button";
import styles from "./Main.module.css";
import { createClient, clearClient } from "~/supabase";
import ButtonLink from "./ButtonLink";
import { ToastArea } from "./Toast";
import Printer from "./Printer";

const Main: Component<{
  children: JSX.Element;
  title?: string;
  backLink: string;
  showSignOutButton?: boolean;
  rightElement?: JSX.Element;
}> = (props) => {
  const navigate = useNavigate();
  const location = useLocation();

  const [userName, setUserName] = createSignal<string>("");
  const signOutAction = async () => {
    try {
      const supabase = createClient();
      await supabase.auth.signOut();
      clearClient();
      navigate("/");
    } catch (error) {
      console.error(error);
    }
  };

  onMount(async () => {
    const supabase = createClient();
    const session = await supabase.auth.getSession();
    if (session == null || session.error || session.data.session == null) {
      console.error("error fetching the user in the session", session.error);
      clearClient();
      navigate("/");
    } else {
      setUserName(
        session.data?.session?.user.user_metadata?.name ||
          session.data?.session?.user.email,
      );
    }
  });

  return (
    <div class={styles.container}>
      <nav class={styles.nav}>
        <Show when={location.pathname !== "/app"} fallback={<div />}>
          <ButtonLink href={props.backLink}>
            <IoChevronBackOutline />
          </ButtonLink>
        </Show>
        <Show
          when={props.showSignOutButton}
          fallback={
            <>
              <h1 class={styles.pageTitle}>{props.title}</h1>
              <div class={styles.printer}>
                <Printer />
              </div>
            </>
          }
        >
          <div class={styles.userMenu}>
            <div>{userName()}</div>
            <div class={styles.right}>
              <div class={styles.printer}>
                <Printer />
              </div>
              <Button onClick={signOutAction}>Sign Out</Button>
            </div>
          </div>
        </Show>
        {props.rightElement}
      </nav>
      <Show when={props.title && props.showSignOutButton}>
        <h1 class={styles.pageTitle}>{props.title}</h1>
      </Show>
      {props.children}
      <ToastArea />
    </div>
  );
};

export default Main;
